var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        [
          _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
          (_vm.report || _vm.reportFromId) &&
          (_vm.canViewDocument || _vm.isStaff)
            ? _c(
                "section",
                { attrs: { slot: "" }, slot: "default" },
                [_c("pdf-renderer", { attrs: { isDocument: false } })],
                1
              )
            : (_vm.report || _vm.reportFromId) &&
              !_vm.canViewDocument &&
              !_vm.isStaff
            ? _c("section", [_vm._m(0)])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "body-content-area-all" } }, [
      _c(
        "div",
        {
          staticClass: "has-text-centered heading",
          staticStyle: { "background-color": "#ededed" },
        },
        [
          _c(
            "h1",
            { staticClass: "is-size-6", staticStyle: { padding: "10px 0px" } },
            [_vm._v("Could Not Access Report")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            height: "100%!important",
            "scrollbar-width": "thin!important",
            position: "static !important",
          },
          attrs: { id: "pagelayout" },
        },
        [_c("p", [_vm._v("Not permitted to view report.")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }